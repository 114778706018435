$font-family-sans-serif : 'Lato', sans-serif !default;
$primary                : #1b385f;
$secondary              : #17D88B;
$success                : #058d4d;
$warning                : #EEC808;
$info                   : #0E70F1;
$danger                 : #ef3d18;
$body-bg                : #f5f5f5;

:root {
  --side-nav-bar-width    : 325px;
  --side-nav-bar-width-ne : -325px;
  --nav-header-height     : 80px;
}
